const navItems = [
  {
    href: "/",
    page: "/",
    texts: "Home",
    className: "home",
  },
  {
    href: "/events",
    page: "/events",
    texts: "Events",
    type: "button",
    className: "products",
  },
  {
    href: "/products",
    page: "/products",
    texts: "Products",
    className: "subProduct",
  },
  {
    href: "/services",
    page: "/services",
    texts: "Services",
    className: "subProduct",
  },
  {
    href: "/systems",
    page: "/systems",
    texts: "Systems",
    className: "subProduct",
  },
  {
    href: "#contact-section",
    page: "/",
    texts: "Contact",
    className: "contact",
    type: "button",
  },
];

export default navItems;
